exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-cookies-policy-index-tsx": () => import("./../../../src/pages/cookies-policy/index.tsx" /* webpackChunkName: "component---src-pages-cookies-policy-index-tsx" */),
  "component---src-pages-disclaimer-index-tsx": () => import("./../../../src/pages/disclaimer/index.tsx" /* webpackChunkName: "component---src-pages-disclaimer-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-tags-index-tsx": () => import("./../../../src/pages/tags/index.tsx" /* webpackChunkName: "component---src-pages-tags-index-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-tag-route-tsx": () => import("./../../../src/templates/tag-route.tsx" /* webpackChunkName: "component---src-templates-tag-route-tsx" */)
}

